<template>
  <div>
    <div class="SensitiveMonitor">
      <el-row :gutter="4" class="mt15" style="display: flex">
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            size="small"
            v-model="formInline.optionDate1"
            type="date"
            :placeholder="$t('SecurityMonitor.SDKBehaviorPage.dataPlaceholder')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions0"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="0.5">
          <span style="line-height: 30px; color: #dcdfe6">~</span>
        </el-col>
        <el-col :span="3">
          <el-date-picker
            :clearable="false"
            style="width: 100%"
            size="small"
            v-model="formInline.optionDate2"
            type="date"
            :placeholder="$t('SecurityMonitor.SDKBehaviorPage.dataPlaceholder')"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions1"
            @change="changeDate"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3">
          <!-- 下拉控件 -->
          <el-select
            style="width: 100%"
            class="el-select1"
            v-model="formInline.type"
            :placeholder="$t('SecurityMonitor.SDKBehaviorPage.selePlaceholder')"
            size="small"
            @change="changeType"
          >
            <el-option
              v-for="item in behavType"
              :key="item.selectValue"
              :label="
                $t(
                  `SecurityMonitor.SDKBehaviorPage.BehaviorType.${item.label1}`
                )
              "
              :value="item.selectValue"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" class="placeInput">
          <el-cascader
            size="small"
            :options="city"
            :props="props"
            v-model="formInline.location"
            collapse-tags
            clearable
            :placeholder="$t('SecurityMonitor.SDKBehaviorPage.locPlaceholder')"
            filterable
          ></el-cascader>
        </el-col>
        <select-input
          :options="devName"
          @selectSearchChange="selectSearchChange"
          @searchList="onSubmit"
        ></select-input>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="outreport">
            {{ $t("public.Derive") }}
          </el-button>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow" style="position: relative">
        <div
          style="
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            right: 5px;
            top: -32px;
            cursor: pointer;
          "
        >
          <!-- 弹出框 -->
          <el-popover trigger="click" popper-class="LinkageTable">
            <el-checkbox-group @change="tableTitleTime" v-model="checkList">
              <el-checkbox
                v-for="(item, index) in tradeSelections"
                :key="index"
                :label="
                  $t(`SecurityMonitor.SDKBehaviorPage.Tablelinkage.${item.eng}`)
                "
                :checked="item.isChecked"
                :disabled="item.flag"
                @click.native="sss(item.eng, index, item.flag)"
                >{{
                  $t(
                    `SecurityMonitor.SDKBehaviorPage.Tablelinkage.${item.name}`
                  )
                }}</el-checkbox
              >
            </el-checkbox-group>
            <img
              slot="reference"
              src="@/images/showConfig.png"
              alt=""
              style="width: 20px; height: 20px"
            />
          </el-popover>
        </div>
        <el-table
          size="mini"
          ref="myTable"
          :data="tableData"
          stripe
          fit
          tooltip-effect="dark"
          class="tableClass"
          @sort-change="sort"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          :default-sort="{ prop: 'CREATETIME', order: 'ascending' }"
        >
          <template>
            <el-table-column
              v-if="$filter.nameFilter(formThead, tradeSelections)"
            >
              <el-table-column
                :show-overflow-tooltip="true"
                width="120"
                v-for="(item, index) in formThead"
                :key="index"
                :label="item.name"
              >
                <template slot-scope="scope">
                  {{ scope.row[item.eng] }}
                </template>
              </el-table-column>
            </el-table-column>
          </template>
          <el-table-column
            fixed
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.DeviceName')"
            prop="DEVICENAME"
            v-if="tradeSelections[tradeSelections.length - 19].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.devicename }}
            </template>
          </el-table-column>
          <el-table-column
            fixed
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.SDKName')"
            prop="SDKNAME"
            v-if="tradeSelections[tradeSelections.length - 18].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.sdkname }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.Behaviortype')"
            prop="TYPE"
            v-if="tradeSelections[tradeSelections.length - 17].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.type }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.appName')"
            prop="NAME"
            v-if="tradeSelections[tradeSelections.length - 16].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.name }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.AppVersion')"
            prop="VERSION"
            v-if="tradeSelections[tradeSelections.length - 15].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.version }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.systemversion')"
            prop="OS_VERSION"
            v-if="tradeSelections[tradeSelections.length - 14].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.osVersion }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.position')"
            v-if="tradeSelections[tradeSelections.length - 13].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.location }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.latitudeLongitude')"
            v-if="tradeSelections[tradeSelections.length - 12].isChecked"
          >
            <template v-slot="{ row }">
              <span
                class="detaildesc"
                v-if="row.longitude == '' || row.latitude == ''"
              ></span>
              <span class="detaildesc" v-else
                >{{ row.longitude }},{{ row.latitude }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.InternetMethod')"
            prop="NET_TYPE"
            v-if="tradeSelections[tradeSelections.length - 11].isChecked"
          >
            <template v-slot="{ row }">
              {{ $filter.surfInterType(row.netType) }}
            </template>
          </el-table-column>

          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.ExternalIP')"
            prop="OUT_IP"
            v-if="tradeSelections[tradeSelections.length - 10].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.outIp }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.IntranetIP')"
            prop="IP"
            v-if="tradeSelections[tradeSelections.length - 9].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.ip }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.WIFISSID')"
            prop="WIFI_SSID"
            v-if="tradeSelections[tradeSelections.length - 8].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.wifiSsid }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.WIFIMAC')"
            prop="WIFI_MAC"
            v-if="tradeSelections[tradeSelections.length - 7].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.wifiMac }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.userName')"
            prop="LOGINNAME"
            v-if="tradeSelections[tradeSelections.length - 6].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.loginname }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.fullName')"
            prop="USERNAME"
            v-if="tradeSelections[tradeSelections.length - 5].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.username }}
            </template>
          </el-table-column>
          <el-table-column
            :show-overflow-tooltip="true"
            width="120"
            :label="$t('SecurityMonitor.SDKBehaviorPage.department')"
            prop="GROUPFULLNAME"
            v-if="tradeSelections[tradeSelections.length - 4].isChecked"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupfullname">{{
                row.groupfullname.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <!-- 产生时间取值createtime, -->
          <el-table-column
            :show-overflow-tooltip="true"
            width="140"
            :label="$t('SecurityMonitor.SDKBehaviorPage.generationTime')"
            prop="CREATETIME"
            v-if="tradeSelections[tradeSelections.length - 3].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.createtime }}
            </template>
          </el-table-column>
          <!-- 上报时间取值 time -->
          <el-table-column
            :show-overflow-tooltip="true"
            width="140"
            :label="$t('SecurityMonitor.SDKBehaviorPage.ReportingTime')"
            prop="TIME"
            v-if="tradeSelections[tradeSelections.length - 2].isChecked"
          >
            <template v-slot="{ row }">
              {{ row.time }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span
                type="text"
                size="small"
                @click="details(row)"
                style="color: #d38a08; cursor: pointer"
                >{{ $t("public.Details") }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </div>
    <!-- 详情弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('SecurityMonitor.SDKBehaviorPage.details')"
      :dialogVisible="outerVisible"
      :flagbtn="false"
      @determine="handleClose"
      :num="'470px'"
    >
      <sdkDetail :detailsArray="detailsArray"></sdkDetail>
    </Eldialog>
  </div>
</template>

<script>
import optionscity from "@/utils/country-level3-data"; //三级城市数据
import Eldialog from "@/components/elDialog/index.vue";
import selectInput from "@/components/selectInput";
import sdkDetail from "./components/sdkBehaviourDetail.vue";
import pagination from "@/components/pagination/page";
export default {
  components: { selectInput, pagination, sdkDetail, Eldialog },

  data() {
    return {
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      groupFullNameShowByIndex: "", //用户部门截取的下标
      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections1: [],
      // 城市
      city: optionscity,
      page: {
        total: 0, //总条数
        orderRule: "DESC",
        pageSize: 10, //每页条数,  默认10条
        currentPage: 1,
        orderColume: "CREATETIME",
      },
      flag: 1,
      props: { multiple: true },
      devName: [
        {
          value: "devicename",
          label: "设备名称",
        },

        {
          value: "sdkname",
          label: "SDK名称",
        },
        {
          value: "name",
          label: "应用名称",
        },
        {
          value: "version",
          label: "应用版本",
        },
        {
          value: "loginname",
          label: "用户名",
        },
        {
          value: "username",
          label: "姓名",
        },
        {
          value: "groupFullName",
          label: "部门",
        },
        {
          value: "os_version",
          label: "系统版本",
        },
        {
          value: "out_ip",
          label: "外网IP",
        },
      ],
      checkList: [], //选中的选项eng数组
      formThead: [], //渲染表头
      cityarrs: [],
      number: 0,
      tableData: [],
      // 表单控件
      formInline: {
        type: "", //下拉
        keyword: "", // 关键字
        location: [],
        optionDate1: "", // 选择日期
        optionDate2: "",
        devNameValue: "设备名称",
      },
      seleShift: "devicename", // 选中下拉框转译
      behavType: [
        {
          selectValue: "",
          label1: "行为类型",
        },
        {
          selectValue: "S_SMS_ACCESS",
          label1: "读取短信记录",
        },
        {
          selectValue: "S_CONTACTS_ACCESS",
          label1: "读取通讯录",
        },
        {
          selectValue: "S_LOCATION",
          label1: "获取地理位置",
        },
        {
          selectValue: "S_GET_SDCARD",
          label1: "读取外部存储",
        },
        {
          selectValue: "S_GET_DEVICE_INFO",
          label1: "获取设备硬件信息",
        },
        {
          selectValue: "S_GET_INSTALLED_APP",
          label1: "获取应用安装列表",
        },
        {
          selectValue: "S_CAMERA",
          label1: "拍照、摄像",
        },
        {
          selectValue: "S_WIFI_SWITCH",
          label1: "开启WIFI",
        },
        {
          selectValue: "S_BLUETOOTH_SWITCH",
          label1: "开启蓝牙",
        },
      ],
      placeholder: "设备名称", //设备名称
      detailsArray: [], //详情
      outerVisible: false, // 详情弹框

      pickerOptions1: {},
      pickerOptions0: {},
      tradeSelections: [
        { eng: "devicename", name: "设备名称", isChecked: true, flag: true },
        { eng: "sdkname", name: "SDK名称", isChecked: true, flag: true },
        { eng: "type", name: "行为类型", isChecked: true, flag: true },
        { eng: "name", name: "应用名称", isChecked: true, flag: true },
        { eng: "version", name: "应用版本", isChecked: true, flag: false },
        { eng: "osVersion", name: "系统版本", isChecked: true, flag: true },
        { eng: "location", name: "位置", isChecked: true, flag: false },
        {
          eng: "longitudeAndLatitude",
          name: "经纬度",
          isChecked: false,
          flag: false,
        },
        { eng: "netType", name: "上网方式", isChecked: true, flag: false },
        { eng: "outIp", name: "外网IP", isChecked: false, flag: false },
        { eng: "ip", name: "内网IP", isChecked: true, flag: false },
        { eng: "wifiSsid", name: "WIFI-SSID", isChecked: true, flag: false },
        { eng: "wifiMac", name: "WIFI-MAC", isChecked: false, flag: false },
        { eng: "loginname", name: "用户名", isChecked: false, flag: false },
        { eng: "username", name: "姓名", isChecked: false, flag: false },
        { eng: "groupfullname", name: "部门", isChecked: false, flag: false },
        { eng: "createtime", name: "产生时间", isChecked: true, flag: false },
        { eng: "time", name: "上报时间", isChecked: true, flag: true },
        { eng: "operator", name: "操作", isChecked: true, flag: true },
      ],//产生时间取值time,上报时间取值 crreatetime
      tradeSelections1: [],
      groupFullNameShowByIndex: "", //用户部门截取的下标
    };
  },

  watch: {
    checkList(val) {
      this.formThead = this.tradeSelections.filter(
        (i) => val.indexOf(i.eng) >= 0
      );
      this.key - this.key + 1;
    },
  },

  created() {
    if (this.$route.query.cityName) {
      let cityArray = this.$route.query.cityName.split(",");
      let options = ["北京", "天津", "上海", "重庆"];
      options.forEach((i, index) => {
        if (cityArray.length == 1) {
          if (cityArray[0] == i) {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, item.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    this.cityarrs.push([item.value, items.value, itemss.value]);
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        } else {
          if (cityArray[0] == i) {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  items.children.forEach((itemss, indexss) => {
                    if (itemss.value == cityArray[1]) {
                      this.cityarrs.push([
                        item.value,
                        item.value,
                        itemss.value,
                      ]);
                    }
                  });
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          } else {
            this.city.forEach((item, index) => {
              if (item.value.includes(cityArray[0])) {
                item.children.forEach((items, indexs) => {
                  if (items.value == cityArray[1]) {
                    items.children.forEach((itemss, indexss) => {
                      this.cityarrs.push([
                        item.value,
                        items.value,
                        itemss.value,
                      ]);
                    });
                  }
                });
              }
            });
            this.$set(this.formInline, "location", this.cityarrs);
          }
        }
      });
    }
  },

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    if (this.$route.query.flag == false) {
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.optionDate1 = this.$route.query.startTime;
      //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.formInline.optionDate2 = getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == true) {
      this.formInline.optionDate1 = this.$route.query.startTime.split(" ")[0];
      //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.formInline.optionDate2 = getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 2) {
      if (
        this.$route.query.startTime == "00" ||
        this.$route.query.startTime == "01" ||
        this.$route.query.startTime == "02" ||
        this.$route.query.startTime == "03" ||
        this.$route.query.startTime == "04" ||
        this.$route.query.startTime == "05" ||
        this.$route.query.startTime == "06" ||
        this.$route.query.startTime == "07" ||
        this.$route.query.startTime == "08" ||
        this.$route.query.startTime == "09" ||
        this.$route.query.startTime == "10" ||
        this.$route.query.startTime == "11" ||
        this.$route.query.startTime == "12" ||
        this.$route.query.startTime == "13" ||
        this.$route.query.startTime == "14" ||
        this.$route.query.startTime == "15" ||
        this.$route.query.startTime == "16" ||
        this.$route.query.startTime == "17" ||
        this.$route.query.startTime == "18" ||
        this.$route.query.startTime == "19" ||
        this.$route.query.startTime == "20" ||
        this.$route.query.startTime == "21" ||
        this.$route.query.startTime == "22" ||
        this.$route.query.startTime == "23" ||
        this.$route.query.startTime == "24"
      ) {
        this.formInline.optionDate1 = this.UTCformat(this.getdatatime()).split(
          " "
        )[0];
        this.formInline.optionDate2 = this.UTCformat(this.getdatatime()).split(
          " "
        )[0];
        this.onSubmit(); //再次调用查询接口
      } else {
        this.formInline.optionDate1 = this.$route.query.startTime;
        this.formInline.optionDate2 = this.$route.query.startTime;
        this.onSubmit(); //再次调用查询接口
      }
      // 当天
    } else if (this.$route.query.flag == 3) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.optionDate1 = this.$route.query.startTime;
      //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.formInline.optionDate2 = getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.flag == 4) {
      //违规行为分布图表
      this.formInline.reason = this.$route.query.reason; //具体类型
      this.formInline.optionDate1 = this.$route.query.starttime;
      //当前日期
      var getDate = function () {
        var date = new Date();
        var year = date.getFullYear();
        var mon = date.getMonth() + 1;
        var day = date.getDate();
        return (
          year +
          "-" +
          (mon < 10 ? "0" + mon : mon) +
          "-" +
          (day < 10 ? "0" + day : day)
        );
      };
      this.formInline.optionDate2 = getDate();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.cityName && this.$route.query.starttime) {
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
      console.log(this.formInline.optionDate1, "this.formInline.startTime");
      console.log(this.formInline.optionDate2, "this.formInline.endTime");
      this.inpSearch();
      this.onSubmit(); //再次调用查询接口
    } else if (this.$route.query.fl == true) {
      this.Day();
      this.getSDKActiveList();
    } else if (this.$route.query.fl == false) {
      this.Mounth();
      this.getSDKActiveList();
    } else if (this.$route.query.card == true) {
      this.card();
      this.getSDKActiveList();
    } else {
      this.formInline.optionDate1 = this.$common.getAnyMonth("-", -1);
      this.formInline.optionDate2 = this.$common.getDate();
      this.getSDKActiveList();
    }
  },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  },
  methods: {
    selectSearchChange() {},
    // 跳转查询
    inpSearch() {
      // this.formInline.deviceName = '应用名称'
      // this.placeholder = '应用名称'
      // this.formInline.deviceName = this.seleShift;
      // console.log(this.formInline.deviceName, '---')
      this.seleShift = "name";

      // for (let index = 0; index < this.typeOptions.length; index++) {
      //   this.flagDlk = true
      //   if (this.typeOptions[index].label == this.$route.query.cityName) {
      //     this.formInline.input = ''
      //     this.formInline.reason = this.typeOptions[index].type
      //     this.flagDlk = false
      //     break
      //   }
      //   if (this.flagDlk) {
      //     this.formInline.reason = ''
      //     this.formInline.input = ''
      //   } else {
      //     this.formInline.reason = this.typeOptions[index].type
      //     this.formInline.input = ''
      //   }
      // }
      // if (this.$route.query.cityName == 'N/A') {
      //   this.formInline.input = ''
      //   this.formInline.location = [['N/A']]
      // } else if (this.$route.query.cityName == '境外') {
      //   this.formInline.input = ''
      //   this.formInline.location = [['境外']]
      // }
      // params.data.provinceurl
      // console.log(this.formInline.input,'this.formInline.input')
      // this.onSubmit();
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 详情关闭按钮
    handleClose() {
      this.outerVisible = false;
    },
    Day() {
      this.formInline.optionDate1 = this.$route.query.dataDay;
      this.formInline.optionDate2 = this.$route.query.dataDay;
      this.onSubmit(); // this.changeDate();
    },
    Mounth() {
      this.formInline.optionDate1 = this.$route.query.dataMounth;
      this.formInline.optionDate2 = this.$route.query.dataDay;
      this.onSubmit();
    },
    card() {
      this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
      this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
    },
    //下拉框变化
    selectlabel(item) {
      this.devName.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.devNameValue;
        }
      });
      this.placeholder = item;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      // 调用查询方法
      this.getSDKActiveList(this.selectVal);
    },
    changeDate() {
      let start = new Date(this.formInline.optionDate1).getTime();
      let end = new Date(this.formInline.optionDate2).getTime();
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != "") {
            return time.getTime() > Date.now() || time.getTime() > end;
          }
          return time.getTime() > Date.now();
        },
      };
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          );
        },
      };
    },
    //行为类型下拉
    changeType(e) {
      this.formInline.type = e;
    },

    //排序
    sort(column) {
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      this.onSubmit();
    },
    //   列表
    async getSDKActiveList(val) {
      this.changeDate();
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        type: this.formInline.type,
        customKey: val ? val.deviceName : this.seleShift,
        customVal: val ? val.InputText : this.formInline.keyword,
        // level: "",
        location: [],
        // deviceName: this.formInline.devNameValue,
        //  
        // exportField: []
      };
      console.log(parmas, "parmas");
      const res = await this.$axios.post(
        "/httpServe/sensitiveSDK/getDataInfo",
        parmas,
        true
      );
      console.log(res, "sdk行为-----");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
      //行为类型转译
      for (var i = 0; i < this.tableData.length; i++) {
        // console.log("这是外层循环第" + this.tableData[i].type + "次");
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i];
        // console.log(obj2, "obj2");
        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.behavType.length; j++) {
          // console.log("这是里层循环第" + this.behavType[j].selectValue + "次");
          if (this.tableData[i].type == this.behavType[j].selectValue) {
            this.tableData[i].type = this.behavType[j].label1;
          }
        }
      }
    },

    // 触发三级联动 我们这是需要传字符串所以需要把数组转为字符串并用"/"间隔
    //查询
    async onSubmit(val) {
      this.selectVal = val;
      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }

      // var arr = [];
      // if (this.formInline.location.length > 0) {
      //   arr = b[0].join("/");
      // }
      // if (this.formInline.keyword.length > 0) {
      // this.page.currentPage = 1;
      // }
      // if (this.$route.query.card == true) {
      //   this.card()
      // }
      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      } else if (this.$route.query.card == true) {
        // debugger
        this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
        this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
        // console.log(this.formInline.startTime, this.formInline.endTime, 'card')
      } else {
        this.changeDate();
      }

      this.page.currentPage = 1;
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        type: this.formInline.type,
        level: "",
        // location: [arr],
        location: location,
        customKey: val.deviceName,
        customVal: val.InputText,
        // deviceName: this.formInline.devNameValue,
         
      };
      console.log(parmas, "查询参数----");
      const res = await this.$axios.post(
        "/httpServe/sensitiveSDK/getDataInfo",
        parmas,
        true
      );
      console.log(res, "sdk查询");
      this.tableData = res.data.content;
      this.page.total = res.data.total;
      //行为类型转译
      for (var i = 0; i < this.tableData.length; i++) {
        // 对于为空的字段，显示N/A
        var obj2 = this.tableData[i];

        for (var key in obj2) {
          if (obj2[key] == null || obj2[key] == "") {
            obj2[key] = "N/A";
          }
        }
        for (var j = 0; j < this.behavType.length; j++) {
          // console.log("这是里层循环第" + this.behavType[j].selectValue + "次");
          if (this.tableData[i].type == this.behavType[j].selectValue) {
            this.tableData[i].type = this.behavType[j].label1;
          }
        }
      }
    },
    // 导出
    async outreport() {
      let arrOut = [];
      this.tradeSelections.forEach((item) => {
        if (item.isChecked == true && item.name != "操作") {
          arrOut.push(item.eng);
          //去重
          this.tradeSelections1 = [...new Set(arrOut)];
          console.log(this.tradeSelections1,'this.tradeSelections1');
          // this.tradeSelections1.unshift("name", "battery")
        }
      });
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMzNzU1NjQsInVzZXJJZCI6MSwiaWF0IjoxNjMyNjU1NTY0fQ.OtDA-uF3NfP8xqj902kqxPu54UPgs8SYVY1nBTl1Ifnvoxw_-MPmcN_DKeYDQXHZfEizztITgMABrO0tBmvshA";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      let groupId = JSON.parse(userString).userId;
      this.userId = groupId;

      let location = null;
      let selectedOptions = this.formInline.location;
      if (selectedOptions) {
        location = [];
        selectedOptions.forEach((element) => {
          location.push(element.flat().join("/"));
        });
      }

      if (this.$route.query.fl == true) {
        this.Day();
      } else if (this.$route.query.fl == false) {
        this.Mounth();
      } else if (this.$route.query.card == true) {
        // debugger
        this.formInline.optionDate1 = this.$route.query.starttime.split(" ")[0];
        this.formInline.optionDate2 = this.$route.query.dataDay.split(" ")[0];
        // console.log(this.formInline.startTime, this.formInline.endTime, 'card')
      } else {
        this.changeDate();
      }
      var parmas = {
        currentPage: this.page.currentPage,
        pageSize: this.page.total,
        startTime: this.formInline.optionDate1,
        endTime: this.formInline.optionDate2,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        type: this.formInline.type,
        level: "",
        location: location,
        deviceName: "",
         

        exportField: this.tradeSelections1,
        customKey: this.selectVal.deviceName
          ? this.selectVal.deviceName
          : this.seleShift,
        customVal: this.selectVal.InputText
          ? this.selectVal.InputText
          : this.formInline.keyword,
      };
      const res = await this.$axios.postExport(
        "/httpExport/sensitiveSDK/exportExcel",
        parmas,
        true,
        this.userId
      );
      console.log(parmas,'sdk导出参数');
      const link = document.createElement("a");
      // let fileName = res.headers["content-disposition"].split("=")[1];
      var fileName = "exportSensitiveSdk_" + Date.parse(new Date()) + ".xls";
      // console.log(fileName);
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    //配置项监听事件
    tableTitleTime(event) {}, //校验只要是数字（包含正负整数，0以及正负浮点数）就返回true
    myIsNaN(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg =
        /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 详情
    details(info) {
      this.outerVisible = true;
      this.detailsArray = info;
      this.detailsArray.decide = eval(this.detailsArray.decide);
    },

    sss(index, key, event) {
      if (event == true) {
        return false;
      }
      if (this.number == 1) {
        if (
          index == "devicename" ||
          index == "sdkname" ||
          index == "type" ||
          index == "microapp" ||
          index == "version" ||
          index == "osVersion" ||
          index == "location" ||
          index == "longitudeAndLatitude" ||
          index == "netType" ||
          index == "outIp" ||
          index == "ip" ||
          index == "wifiSsid" ||
          index == "wifiMac" ||
          index == "username" ||
          index == "loginname" ||
          index == "name" ||
          index == "groupfullname" ||
          index == "createtime" ||
          index == "time" ||
          index == "operator"
        ) {
          this.tradeSelections[key].isChecked =
            !this.tradeSelections[key].isChecked;
        }
        this.number = 0;
      } else {
        this.number = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SensitiveMonitor {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
.location {
  // margin-top: -2px;
  &::v-deep {
    & ::placeholder {
      color: #606266;
    }
  }
}
@-moz-document url-prefix() {
  .location {
    // margin-top: -2px;

    &::v-deep {
      & ::placeholder {
        color: #606266;
        opacity: 1 !important; /*火狐默认有opacity:0.4; */
      }
    }
  }
}

/* 位置 */
.el-cascader {
  width: 100%;
}
.el-cascader__tags  {
  position: absolute;
  left: 0;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  line-height: normal;
  text-align: left;
  box-sizing: border-box;
  flex-wrap: nowrap;
  overflow: hidden;
}
.textStyle {
  display: inline-block;
  font-size: 12px;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
}
.el-popover {
  min-width: 150px !important;
  text-align: left !important;
  font-size: 12px !important;
}
/* 遮罩层 */
.el-dialog {
  border-radius: 8px;
  .el-dialog__header {
    padding: 6px;
    background: #1e89e5;
    border-radius: 6px 6px 0 0;
    .el-dialog__title {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 10px !important;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
    }
  }
  .el-dialog__body {
    padding: 5px 12px 24px 20px !important;
    height: 469px;
    overflow: auto;
    margin-right: 12px;
  }
  .el-form-item__label {
    font-size: 12px !important;
  }
  .el-dialog__footer {
    padding: 4px 20px !important;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-radius: 0 0 6px 6px;
  }
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
/* 设置高优先，以覆盖内联样式 Table组件固定列底部有一条白线
::v-deep  .el-table__fixed{
  height: 100vh !important;
}
::v-deep  .el-table__fixed-right{
  height: 100vh !important;
}*/
::v-deep .el-table__fixed::before {
  background-color: transparent !important; /*背景色完全透明*/
}
::v-deep .el-table__fixed-right::before {
  background-color: transparent !important; /*背景色完全透明*/
}
.placeInput {
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-cascader__tags .el-cascader__search-input::placeholder {
    color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep
    .el-cascader__tags
    .el-cascader__search-input::-webkit-input-placeholder {
    color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-cascader__tags .el-cascader__search-input::-moz-placeholder {
    color: #606266 !important;
    opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-cascader__tags .el-cascader__search-input:-ms-input-placeholder {
    color: #606266 !important;
  }
}
</style>