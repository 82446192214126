<template id="temp">
  <!-- vue实例外创建 -->
  <div>
    <div class="detailContent" :data="detailsArray">
      <!-- 应用信息 -->
      <!-- <p class="detailTitle">应用信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">应用名称：</span>
            <span class="detaildesc">{{ detailsArray.name }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">应用版本：</span>
            <span class="detaildesc">{{ detailsArray.version }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">应用包名：</span>
            <span class="detaildesc">{{ detailsArray.packagename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="vertical-align: top;">应用MD5：</span>
            <span class="detaildesc" style="vertical-align: top;">{{ detailsArray.appMd5 }}</span>
          </div>
        </div>
      </div> -->

      <el-descriptions :title="$t('SecurityMonitor.SDKBehaviorPage.AppInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.appName')">{{ detailsArray.name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.AppPackageName')">{{ detailsArray.packagename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.AppVersion')">
          {{ detailsArray.version }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.AppMD5')">{{ detailsArray.appMd5 }}</el-descriptions-item>
      </el-descriptions>



      <!-- 基本信息 -->
      <!-- <p class="detailTitle">基本信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">设备名称：</span>
            <span class="detaildesc">{{ detailsArray.devicename }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">姓名：</span>
            <span class="detaildesc">{{ detailsArray.username }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">用户部门：</span>
            <span :title="detailsArray.groupfullname" class="detaildesc" style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-all;
                  line-height:10px;
                ">{{ detailsArray.groupfullname.substr(groupFullNameShowByIndex) }}</span>
          </div>
  <div class="group">
              <span class="detailLabel">设备厂商：</span>
              <span class="detaildesc">{{ detailsArray.operator }}</span>
            </div>
            <div class="group">
              <span class="detailLabel">合规状态：</span>
              <span class="detaildesc">{{ detailsArray.isDanger }}</span>
            </div> 
          <div class="group">
            <span class="detailLabel">系统版本：</span>
            <span class="detaildesc">{{ detailsArray.osVersion }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">设备IP：</span>
            <span class="detaildesc">{{ detailsArray.ip }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">用户名：</span>
            <span class="detaildesc">{{ detailsArray.loginname }}</span>
          </div>

          <div class="group">
            <span class="detailLabel">设备型号：</span>
            <span class="detaildesc">{{ detailsArray.model }}</span>
          </div>
          <div class="group">
              <span class="detailLabel">设备越狱：</span>
              <span class="detaildesc"></span>
            </div> 
          <div class="group">
            <span class="detailLabel">上网方式：</span>
            <span class="detaildesc">{{ detailsArray.netType }}</span>
          </div>
          <div class="group">
            <span class="detailLabel" style="display: block; float: left">设备IMEI：</span>
            <span class="detaildesc" style="display: block; float: left; text-align: justify">{{ detailsArray.imei }}</span>
          </div>
        </div>
      </div> -->

      <el-descriptions :title="$t('SecurityMonitor.SDKBehaviorPage.BasicInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.DeviceName')">{{ detailsArray.devicename }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.userName')">{{ detailsArray.loginname }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.fullName')">{{ detailsArray.username }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.EquipmentModel')">{{ detailsArray.model }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.UserDepartment')">{{  detailsArray.groupfullname.substr(groupFullNameShowByIndex) }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.InternetMethod')">{{ $filter.surfInterType(detailsArray.netType) }}</el-descriptions-item>
        <!-- <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.deviceManufacturer')">{{ detailsArray.operator }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.ComStatus')">{{ detailsArray.isDanger }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.systemversion')">{{ detailsArray.osVersion }}</el-descriptions-item>
         <!-- 2024.7.10wjw说产品要设备IMEI根据设备类型展示；android: 设备IMEI; iOS: UDID;Linux:网卡Mac; Windows:网卡Mac -->
       <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Android'" :label="$t('public.IMEIAndroid')">{{ detailsArray.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='iOS'"  :label="$t('public.IMEIiOS')">{{ detailsArray.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Linux'"  :label="$t('public.IMEIMac')">{{ detailsArray.imei }}</el-descriptions-item>
       <el-descriptions-item  v-if="$common.getSystemName(detailsArray.osVersion)=='Windows'" :label="$t('public.IMEIMac')">{{ detailsArray.imei }}</el-descriptions-item>
       <el-descriptions-item v-if="$common.getSystemName(detailsArray.osVersion)=='Unknown'" :label="$t('public.IMEIUnknown')">{{ detailsArray.imei }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.DeviceIP')">{{ detailsArray.ip }}</el-descriptions-item>
      </el-descriptions>


      <!-- 位置信息 -->
      <!-- <p class="detailTitle">位置信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">国家：</span>
            <span class="detaildesc">{{ detailsArray.country }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">城市：</span>
            <span class="detaildesc">{{ detailsArray.city }}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">省份：</span>
            <span class="detaildesc">{{ detailsArray.province }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">经纬度：</span>
            <span class="detaildesc" v-if="detailsArray.longitude == ''"></span>
            <span class="detaildesc" v-else>{{ detailsArray.longitude }},{{ detailsArray.latitude }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group">
          <span class="detailLabel">详细地址：</span>
          <span class="detaildesc">{{ detailsArray.location }}</span>
        </div>
      </div> -->
      <el-descriptions :title="$t('SecurityMonitor.SDKBehaviorPage.positionInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.country')">{{ detailsArray.country }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.province')">{{ detailsArray.province }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.city')">{{ detailsArray.city }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.latitudeLongitude')" v-if="detailsArray.longitude == ''"></el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.latitudeLongitude')" v-else>{{ detailsArray.longitude }},{{ detailsArray.latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.Address')">{{ detailsArray.location }}</el-descriptions-item>
      </el-descriptions>


      <!-- 风险信息 -->
      <!-- <p class="detailTitle">风险信息</p>
      <div class="detailbox">
        <div class="boxLeft">
          <div class="group">
            <span class="detailLabel">行为类型：</span>
            <span class="detaildesc">{{ detailsArray.type }}</span>
          </div>
          <div class="group">
            <span class="detailLabel">产生时间：</span>
            <span class="detaildesc">{{ detailsArray.createtime}}</span>
          </div>
        </div>
        <div class="boxRight">
          <div class="group">
            <span class="detailLabel">行为级别：</span>
            <span class="detaildesc" v-if="detailsArray.level == '3'" style="color: #d9001b">高</span>
            <span class="detaildesc" v-if="detailsArray.level == '2'" style="color: #f59a23">中</span>
            <span class="detaildesc" v-if="detailsArray.level == '1'" style="color: #00cdcd">低</span>
          </div>
          <div class="group">
            <span class="detailLabel">上报时间：</span>
            <span class="detaildesc">{{
              detailsArray.updatetime
            }}</span>
          </div>
        </div>
      </div>
      <div class="boxBot">
        <div class="group" style="width: 100%;">
          <div class="detailLabel" style="
           display: block; float: left;
                height: 24px;
                line-height: 24px;
                text-align: left;
                pading-right: 5px;
              ">
            研判步骤：
          </div>
          <div style="display: block; float: left; text-align: justify">
            <p v-for="(e, i) in detailsArray.decide" :key="i" style="
                overflow: hidden;
                color: #606266;
                margin-left: 0;
                line-height: 24px;
                word-wrap: break-word;
                word-break: break-all;
                color:#606266
              ">
              {{ i + 1 }}、{{ e }}
            </p>
          </div>
        </div>
      </div> -->

      <el-descriptions :title="$t('SecurityMonitor.SDKBehaviorPage.riskInfor')" :column="2">
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.Behaviortype')">{{ detailsArray.type }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.BehaviorLevel')">
          <span class="detaildesc" v-if="detailsArray.level == '3'" style="color: #d9001b; cursor: auto">{{ $t("SecurityMonitor.SDKBehaviorPage.tall") }}</span>
          <span class="detaildesc" v-if="detailsArray.level == '2'" style="color: #f59a23; cursor: auto">{{ $t("SecurityMonitor.SDKBehaviorPage.centre") }}</span>
          <span class="detaildesc" v-if="detailsArray.level == '1'" style="color: #00cdcd; cursor: auto">{{ $t("SecurityMonitor.SDKBehaviorPage.low") }}</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.generationTime')">{{  detailsArray.time }}</el-descriptions-item>
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.ReportingTime')">{{detailsArray.updatetime }}</el-descriptions-item>
        <!-- <el-descriptions-item :label="响应动作" :span="2">{{ detailsArray.rule }}</el-descriptions-item> -->
        <el-descriptions-item :label="$t('SecurityMonitor.SDKBehaviorPage.JudgmentSteps')" :span="2" :contentStyle="{'display': 'block'}">
          <div v-for="(e, i) in detailsArray.decide" :key="i"> {{ i + 1 }}、{{ e }}</div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detailsArray: {
      type: Object,
      default: {},
      
    }
  },
  data() {
    return {groupFullNameShowByIndex: ''}
  },

  methods: {},

  created() {},

  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  }
}
</script>
<style lang="scss" scoped>
.detailContent {
  width: 100%;
  // height: 469px !important;
  //overflow: auto !important;
  .detailTitle {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
  }
  .detailbox {
    width: 100%;
    display: flex;
    font-size: 12px;
    .boxLeft {
      width: 50%;
      padding-left: 68px;
      line-height: 24px;
      .group {
        height: 24px;
        line-height: 24px;
        margin-top: 8px;
        .detailLabel {
          width: 44%;
          text-align: left;
        }
        .detaildesc {
          word-break: break-all !important;
          word-wrap: break-word !important;
          width: 56%;
          display: inline-block;
        }
      }
    }
    .boxRight {
      width: 50%;
      .group {
        height: 24px;
        line-height: 24px;
        margin-top: 8px;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        text-align: left;
        .detailLabel {
          text-align: left;
        }
        .detaildesc {
          width: 67%;
          display: inline-block;
          overflow-y: visible;
        }
      }
    }
  }
  .boxBot {
    width: 100%;
    padding-left: 68px;
    font-size: 12px;
    .group {
      height: 24px;
      line-height: 24px;
      margin-top: 8px;
      .detailLabel {
        height: 24px !important;
        line-height: 24px;
        text-align: left;
        padding-right: 5px;
        display: inline-block;
      }
      .detaildesc {
        word-break: break-all !important;
        word-wrap: break-word !important;
        width: 78%;
        display: inline-block;
      }
    }
  }

  .version-label {
    background: #b787df;
  }
  .version-content {
    background: #d182e0;
  }
}
::v-deep .el-descriptions__title {
  color: #606266;
  font-size: 14px;
}
::v-deep .el-descriptions__body {
  color: #606266;
  font-size: 12px;
  background-color: #fff;
  padding-left: 70px;
}
</style>
